.custom-popup {
  color: black;
}

.custom-popup .mapboxgl-popup-content {
  width: auto;
  background-color: #eaeaf0ff;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 6px 6px 6px rgba(1, 1, 1, 0.4);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  border-bottom: none;
  border-top-color: #bcbcbc;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  align-self: center;
  border-top: none;
  border-bottom-color: #bcbcbc;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  align-self: center;
  border-left: none;
  border-right-color: #bcbcbc;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  align-self: center;
  border-right: none;
  border-left-color: #bcbcbc;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-top: none;
  border-left: none;
  border-bottom-color: #bcbcbc;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  align-self: flex-end;
  border-top: none;
  border-right: none;
  border-bottom-color: #bcbcbc;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  align-self: flex-end;
  border-bottom: none;
  border-right: none;
  border-top-color: #bcbcbc;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-bottom: none;
  border-left: none;
  border-top-color: #bcbcbc;
}
